import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Spinner } from "react-bootstrap";
import { baseUrl } from "../../../services/config";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import AccountVoucherPrint from "./AccountVoucherPrint";
import Receipt from "./Receipt";
import Paginations from "./Paginations";
import EditVoucherModal from "./EditVoucherModal";
import { Modal as AntModal } from "antd";
import { Toaster, toast } from "react-hot-toast";
import ReactToPrint from "react-to-print";
import { upperCase } from "lodash";
import { PremissionChecker } from "../../../services/PremissionChecker";
import { useSelector } from "react-redux";
import AllAccountVoucherPrint from "./AllAccountVoucherPrint";

export default function SearchVoucher({ allcoucherid }) {
  const pageStyle = `
  @page {
    size: A4 portrait !important;
  }
  `;
  const user = useSelector((state) => state.user.user);

  const [searchID, setsearchID] = useState("");
  const [searchnumber, setsearchnumber] = useState("");
  const [searchstartdate, setsearchstartdate] = useState("");
  const [searchenddate, setsearchenddate] = useState("");
  const [loading, setloading] = useState(false);

  const [voucherData, setvoucherData] = useState([]);
  const [singleVoucherData, setSingleVoucherData] = useState([]);
  const [yearId, setYearId] = useState("");
  const [sessionData, setSessionData] = useState([]);
  const [sessionName, setSessionName] = useState("");

  const [selectedvoucher, setselectedvoucher] = useState({});
  const [viewmodal, setviewmodal] = useState(false);
  let authtoken = localStorage.getItem("userToken");

  const [printvouchermodal, setprintvouchermodal] = useState(false);
  const [allprintvouchermodal, setallprintvouchermodal] = useState(false);
  const [summaryModel, setSummaryModel] = useState(false);
  const [summaryData, setsummaryData] = useState({});
  const [summary, setSummary] = useState({});
  const [links, setLinks] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [editItem, setEditItem] = useState(false);
  const [deleteModal, setDeleModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [receiptvouchermodal, setReceiptvouchermodal] = useState(false);
  const componentRef = React.useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const [text, setText] = React.useState("old boring text");
  const [printLoading, setPrintLoading] = React.useState(false);
  const [showPrintBtn, setShowPrintBtn] = useState(false);

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);
  const [directorSign, setDirectorSign] = useState(0);

  useEffect(() => {
    getSettingData();
    sessions();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
        setDirectorSign(data.payload.settings.director_sign);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_financial_year == "1") {
            setYearId(session.id);
            console.log(session);
            setSessionName(session.year);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const seacrhVoucher = (url) => {
    setloading(true);
    let data = {
      start_date: searchstartdate,
      end_date: searchenddate,
      voucher_type_id: searchID,
      voucher_no: searchnumber,
      year_id: yearId,
    };

    console.log(data);

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("voucher filter wali api ");
        setvoucherData([]);
        setSingleVoucherData([]);
        if (data.metadata) {
          if (data.metadata.success) {
            console.log(data.payload, "abhi abhi");
            setShowPrintBtn(true);
            setvoucherData(data.payload.data);
            setLinks(data.payload.links);
            setSingleVoucherData(data.payload);
          }
        } else {
          setvoucherData([]);
          setSingleVoucherData([]);
          setShowPrintBtn(false);
        }

        setloading(false);
        console.log(data);
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const fetxhingSummaryData = (id) => {
    let data = {
      voucher_id: id,
    };

    fetch(`${baseUrl}/voucher_summary`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("voucher summary wali api ", data);
        if (data.metadata) {
          if (data.metadata.success) {
            setSummary(data.payload);
            setsummaryData(data.payload);
            setSummaryModel(true);
          }
        } else {
          setSummaryModel(true);
        }

        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onPaginate = (link) => {
    seacrhVoucher(link.url);
  };

  const handleEdit = (data) => {
    setEditItem(data);
    setEditModal(true);
  };

  const onDeleteClick = () => {
    setDeleteLoading(true);

    fetch(`${baseUrl}/voucher/${selectedId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.metadata) {
          if (data.metadata.success) {
            toast.success("Deleted Successfully");
            setDeleModal(false);
            seacrhVoucher(`${baseUrl}/voucher_filter`);
          } else {
            toast.error(data.metadata.message);
          }
        }
        setDeleteLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setDeleteLoading(false);
        toast.error("Opps somthing went wronge");
      });
  };

  const todayDate = () => {
    let d = new Date();
    var curr_moth = ("0" + (d.getMonth() + 1)).slice(-2);
    var curr_date = ("0" + d.getDate()).slice(-2);
    let newdate = `${d.getFullYear()}-${curr_moth}-${curr_date}`;
    return newdate;
  };

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setPrintLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setPrintLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setPrintLoading, setText]);

  return (
    <div style={{ padding: 15 }}>
      <Toaster />
      <div>
        <div className="row">
          <div className="col-md-2">
            <label>
              <b>Select Session</b>
            </label>
            <div className="d-flex flex-column ">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                value={yearId}
                aria-label="Default select example"
                onChange={(e) => {
                  const data = sessionData.find((ses) => {
                    return ses.id == e.target.value;
                  });
                  console.log(data);
                  setSessionName(data.year);
                  setYearId(e.target.value);
                }}
              >
                <option value="">Select Session</option>
                {sessionData &&
                  sessionData.map((item) => (
                    <option value={item.id}>{item.year}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-2">
            <label>voucher Type </label>

            <select
              class="form-select form-control"
              name="voucher_type_id"
              value={searchID}
              onChange={(e) => setsearchID(e.target.value)}
              style={{ backgroundColor: "white" }}
              aria-label="Default select example"
            >
              <option selected value="">
                Select Voucher Type
              </option>
              {allcoucherid.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-2">
            <label>Voucher No </label>
            <input
              onChange={(e) => setsearchnumber(e.target.value)}
              className="form-control"
              placeholder="Search"
            />
          </div>

          <div className="col-md-2">
            <label>Start Date </label>
            <input
              onChange={(e) => setsearchstartdate(e.target.value)}
              className="form-control "
              type="date"
              max="9999-12-30"
              placeholder="Search"
            />
          </div>

          <div className="col-md-2">
            <label>End Date</label>
            <input
              onChange={(e) => setsearchenddate(e.target.value)}
              className="form-control "
              type="date"
              max="9999-12-30"
              placeholder="Search"
            />
          </div>

          <div className="d-flex col-md-4 mt-5">
            <div>
              <button
                className="btn btn-primary mr-4"
                onClick={() => {
                  seacrhVoucher(`${baseUrl}/voucher_filter`);
                }}
              >
                Search
              </button>
            </div>

            {showPrintBtn ? (
              <ReactToPrint
                pageStyle="auto"
                documentTitle="Print All Vouchers"
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    disabled={printLoading ? true : false}
                    className="btn btn-primary"
                  >
                    {printLoading ? "Printing..." : "Print All Vouchers"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            ) : null}
          </div>

          {/* <div className="col-md-4">
            <div style={{ paddingTop: "10px" }}>
              <button
                className="btn btn-success button_blue button_blue"
                onClick={() => {
                  seacrhVoucher(`${baseUrl}/voucher_filter`);
                }}
              >
                Print All Vouchers
              </button>
            </div>
          </div> */}
        </div>
      </div>

      <div>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner class="text-center" variant="info" animation="border" />
          </div>
        ) : (
          <div>
            <Table responsive>
              <thead>
                <tr className="text-center">
                  <th>Sr</th>
                  <th>Voucher Type Name</th>
                  <th>Session Year</th>
                  <th>Cheque Number</th>
                  <th>Voucher Number</th>
                  <th>Remarks</th>
                  <th>Summary</th>
                  <th>Receipt</th>
                  <th>Edit</th>
                  <th>Delete</th>
                  <th>Print</th>
                </tr>
              </thead>

              <tbody>
                {(voucherData && voucherData.length > 0
                  ? voucherData
                  : singleVoucherData
                )?.map((item, index) => {
                  return (
                    <tr key={index} className="text-center">
                      <td>{index + 1}</td>
                      <td
                        title={
                          item.voucher_type.name.length > 20
                            ? item.voucher_type.name
                            : null
                        }
                        style={{ textAlign: "center", cursor: "pointer" }}
                      >
                        {item.voucher_type.name.length > 20
                          ? item.voucher_type.name.substring(0, 17) + "..."
                          : item.voucher_type.name}
                      </td>
                      <td>{item.session.year}</td>
                      <td>{item.check_no != null ? item.check_no : "---"}</td>
                      <td>{item.voucher_no}</td>
                      <td>
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          {item.remarks == null ? (
                            <SVG
                              title="No Remarks"
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Code/Info-circle.svg"
                              )}
                            />
                          ) : (
                            <SVG
                              title={item.remarks}
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Code/Info-circle.svg"
                              )}
                            />
                          )}
                        </span>
                      </td>
                      <td>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            fetxhingSummaryData(item.id);
                          }}
                          className="svg-icon svg-icon-md svg-icon-primary"
                        >
                          <SVG
                            src={toAbsoluteUrl("/media/svg/files/doc.svg")}
                          />
                        </span>
                      </td>

                      <td>
                        {item.voucher_type.name != "DN" &&
                        item.voucher_type.name != "JV" ? (
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setReceiptvouchermodal(true);
                              setselectedvoucher(item);
                            }}
                            className="svg-icon svg-icon-md svg-icon-primary"
                          >
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Devices/Printer.svg"
                              )}
                            />
                          </span>
                        ) : null}
                      </td>

                      <td>
                        {PremissionChecker(user, "account.can_edit_voucher") ? (
                          item.resolved == "0" ? (
                            <a
                              key={index}
                              className="btn btn-icon btn-light btn-hover-primary btn-sm "
                              onClick={() => handleEdit(item)}
                            >
                              <span
                                key={index}
                                className="svg-icon svg-icon-md svg-icon-primary"
                              >
                                <SVG
                                  key={index}
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Communication/Write.svg"
                                  )}
                                />
                              </span>
                            </a>
                          ) : (
                            ""
                          )
                        ) : null}
                      </td>
                      <td>
                        {PremissionChecker(
                          user,
                          "account.can_delete_voucher"
                        ) ? (
                          item.resolved == "0" ? (
                            <a
                              key={index}
                              className="btn btn-icon btn-light btn-hover-danger btn-sm "
                              onClick={() => {
                                setSelectedId(item.id);
                                setDeleModal(true);
                              }}
                            >
                              <span
                                style={{ cursor: "pointer" }}
                                className={
                                  "svg-icon svg-icon-md svg-icon-danger"
                                }
                              >
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/General/Trash.svg"
                                  )}
                                />
                              </span>
                            </a>
                          ) : (
                            ""
                          )
                        ) : null}
                      </td>
                      <td>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setprintvouchermodal(true);
                            setselectedvoucher(item);
                          }}
                          className="svg-icon svg-icon-md svg-icon-primary"
                        >
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Devices/Printer.svg"
                            )}
                          />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}
      </div>

      <Modal size="xl" show={viewmodal}>
        <Modal.Body>
          <div className="card">
            <Table responsive>
              <thead>
                <tr id="tbl">
                  <th>SR#</th>
                  <th>Sub Account Titel</th>
                  <th>Debit</th>
                  <th>Credit</th>
                  <th>Remarks</th>
                </tr>
              </thead>

              <tbody>
                {selectedvoucher.general_ledgers == undefined ? null : (
                  <>
                    {selectedvoucher.general_ledgers.map((item, index) => (
                      <tr>
                        <td style={{ textAlign: "start" }}>{index + 1}</td>
                        <td style={{ textAlign: "start" }}>
                          {item.sub_account == null
                            ? ""
                            : item.sub_account.title}
                        </td>
                        <td style={{ textAlign: "start" }}>{item.debit}</td>
                        <td style={{ textAlign: "start" }}>{item.credit}</td>
                        <td style={{ textAlign: "start" }}>
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            {item.remarks == null ? (
                              <SVG
                                title="No Remarks"
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Code/Info-circle.svg"
                                )}
                              />
                            ) : (
                              <SVG
                                title={item.remarks}
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Code/Info-circle.svg"
                                )}
                              />
                            )}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              onClick={() => {
                setviewmodal(false);
              }}
              style={{ width: "150px", height: "40px" }}
              className="btn btn-danger btn_width"
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* print voucher */}
      {printvouchermodal && (
        <AccountVoucherPrint
          show={printvouchermodal}
          onHide={() => {
            setprintvouchermodal(false);
          }}
          selectedVoucher={selectedvoucher}
          reload={() => seacrhVoucher(`${baseUrl}/voucher_filter`)}
        />
      )}

      {/* print all voucher */}
      <div
        ref={componentRef}
        id="printSection"
        style={{ visibility: "hidden" }}
      >
        <AllAccountVoucherPrint
          selectedAllVoucher={
            Array.isArray(voucherData) && voucherData.length > 0
              ? voucherData
              : Array.isArray(singleVoucherData)
              ? singleVoucherData
              : []
          }
        />
      </div>

      {/* {allprintvouchermodal && (
        <AllAccountVoucherPrint
          show={allprintvouchermodal}
          onHide={() => {
            setallprintvouchermodal(false);
          }}
          selectedAllVoucher={voucherData}
          selectedVoucher={selectedvoucher}
          // reload={() => seacrhVoucher(`${baseUrl}/voucher_filter`)}
        />
      )} */}

      {receiptvouchermodal && (
        <Receipt
          show={receiptvouchermodal}
          onHide={setReceiptvouchermodal}
          selectedVoucher={selectedvoucher}
          reload={() => seacrhVoucher(`${baseUrl}/voucher_filter`)}
        />
      )}

      {/* ///summarymodel */}
      <Modal size="xl" show={summaryModel}>
        <div id="voucher-p">
          <Modal.Body>
            {console.log(summaryData)}
            <div>
              <div className="page1-content">
                <div
                  style={{
                    display: "flex",
                    padding: "10px",
                  }}
                >
                  <img
                    src={icon}
                    style={{ height: 100, width: 110 }}
                    alt="icon"
                  />
                  <h3
                    style={{
                      color: "#B4959D",
                      // width: "20%",
                      paddingLeft: "10px",
                      paddingTop: "25px",
                    }}
                  >
                    {unitName}
                  </h3>
                </div>
                <hr className="page1-line" />
                <div className="dataPage1">
                  <div className="detailPage1">
                    <h5>Financial Year :- {summaryData?.session?.year}</h5>
                    <h5>Printing Date :- {todayDate()}</h5>
                    <h5>Printing Time :- {new Date().toLocaleTimeString()}</h5>
                  </div>
                  <div className="voucherDetailPage1">
                    <h5>Voucher No:- {summaryData?.voucher_no}</h5>
                    <h5>Voucher Date:- {summaryData?.date}</h5>
                    {summaryData?.check_no != null ? (
                      <h5>Cheque No:- {summaryData?.check_no} </h5>
                    ) : null}
                  </div>
                </div>
                <hr className="page1-line" />
                <table className="page1-table">
                  <tr className="page1-tr">
                    <th style={{ textAlign: "center" }} className="page1-th">
                      Sr No#
                    </th>
                    <th style={{ textAlign: "center" }} className="page1-th">
                      Acc Code
                    </th>
                    <th style={{ textAlign: "center" }} className="page1-th">
                      Account Name / Description
                    </th>
                    <th style={{ textAlign: "center" }} className="page1-th">
                      Debit
                    </th>
                    <th style={{ textAlign: "center" }} className="page1-th">
                      Credit
                    </th>
                  </tr>
                  {summaryData?.summary_general_ledger?.map((item, index) => {
                    return (
                      <tr className="page1-tr">
                        <td
                          style={{ textAlign: "center" }}
                          className="page1-td"
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{ textAlign: "center" }}
                          className="page1-td"
                        >
                          {item?.sub_account?.acode}
                        </td>
                        <td
                          style={{ textAlign: "center" }}
                          className="page1-td"
                        >
                          {item?.sub_account?.title}
                        </td>
                        <td className="page1-td text-right">{item.debit}</td>
                        <td className="page1-td text-right">{item.credit}</td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td className="page1-td"></td>
                    <td className="page1-td"></td>
                    <td className="page1-td text-right">
                      <b>Total</b>
                    </td>
                    <td className="page1-td text-right">
                      <b>
                        {summary.total_debit == null
                          ? 0
                          : parseInt(summary.total_debit)}
                      </b>
                    </td>
                    <td className="page1-td text-right">
                      <b>
                        {summary.total_credit == null
                          ? 0
                          : parseInt(summary.total_credit)}
                      </b>
                    </td>
                  </tr>
                </table>
                <div className="lower-content">
                  <div className="lower1">
                    <div className="lower2">
                      <p>Prepared By</p>
                    </div>
                  </div>
                  <div className="lower1">
                    <div className="lower2">
                      <p>Accountant</p>
                    </div>
                  </div>
                  <div className="lower1" id="lowerLast">
                    <div className="lower2">
                      {directorSign == 0 ? (
                        <p>Chief Executive</p>
                      ) : (
                        <p>Director</p>
                      )}
                    </div>
                  </div>
                </div>
                <hr className="page1-line" />
                <p className="indexNumber1">Page 1 of 1</p>
              </div>
            </div>
          </Modal.Body>
        </div>
        <Modal.Footer>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {/* <button
               onClick={() => {
                setshow2(true)
              }}
              className="btn btn-success button_blue mx-2"
            >
              Submit
            </button> */}
            <ReactToPrint
              documentTitle="Voucher Summary"
              pageStyle={pageStyle}
              onAfterPrint={handleAfterPrint}
              onBeforeGetContent={handleOnBeforeGetContent}
              onBeforePrint={handleBeforePrint}
              removeAfterPrint={true}
              trigger={() => (
                <button
                  disabled={printLoading ? true : false}
                  className="btn btn-success button_blue button_blue"
                >
                  {printLoading ? "Printing..." : "Print"}
                </button>
              )}
              content={() => componentRef.current}
            />

            <button
              onClick={() => {
                setSummaryModel(false);
              }}
              style={{ width: "150px", height: "40px", marginLeft: "10px" }}
              className="btn btn-danger btn_width"
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <Paginations links={links} onPaginate={onPaginate} />

      <EditVoucherModal
        deveditModal={editModal}
        hide={() => setEditModal(false)}
        editItem={editItem}
        reload={() => seacrhVoucher(`${baseUrl}/voucher_filter`)}
      />

      <AntModal
        title="Delete Voucher"
        confirmLoading={deleteLoading}
        centered
        okText="Delete"
        okButtonProps={{ style: { backgroundColor: "red" } }}
        open={deleteModal}
        onOk={onDeleteClick}
        onCancel={() => setDeleModal(false)}
      >
        <p>Are you sure you want to delete it</p>
      </AntModal>

      {summaryModel && (
        <div style={{ visibility: "hidden" }}>
          <div ref={componentRef} id="printSection">
            <div>
              <div className="page1-content">
                <div className="d-flex">
                  <img
                    src={icon}
                    style={{ height: 120, width: 110 }}
                    alt="icon"
                  />
                  <div className="col-md-10 pl-5">
                    <h3 className="text-primary">{unitName}</h3>
                    <h3 className="text-primary">Account Voucher Summary</h3>
                  </div>
                </div>

                <div
                  className="d-flex justify-content-between"
                  style={{ margin: "15px 0" }}
                >
                  <div>
                    <h3 style={{ fontSize: "19px" }}>
                      Financial Year :- {summaryData?.session?.year}
                    </h3>
                    <h3 style={{ fontSize: "19px" }}>
                      Printing Date :- {todayDate()}
                    </h3>
                    <h3 style={{ fontSize: "19px" }}>
                      Printing Time :- {new Date().toLocaleTimeString()}
                    </h3>
                  </div>
                  <div>
                    <h3 style={{ fontSize: "19px" }}>
                      Voucher No:- {summaryData?.voucher_no}
                    </h3>
                    <h3 style={{ fontSize: "19px" }}>
                      Voucher Date:- {summaryData?.date}
                    </h3>
                    {summaryData?.check_no != null ? (
                      <h3 style={{ fontSize: "19px" }}>
                        Cheque No:- {summaryData?.check_no}{" "}
                      </h3>
                    ) : null}
                  </div>
                </div>

                <table
                  className="page1-table"
                  style={{
                    whiteSpace: "normal",
                    padding: "1px",
                    marginTop: "20px",
                    color: "black",
                  }}
                >
                  <tr
                    className="page1-tr"
                    style={{ whiteSpace: "normal", padding: "1px" }}
                  >
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                      className="page1-th"
                    >
                      Sr No#
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                      className="page1-th"
                    >
                      Acc Code
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                      className="page1-th"
                    >
                      Account Name / Description
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                      className="page1-th"
                    >
                      Debit
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                      className="page1-th"
                    >
                      Credit
                    </th>
                  </tr>
                  {summaryData?.summary_general_ledger?.map((item, index) => {
                    return (
                      <tr
                        className="page1-tr"
                        style={{ whiteSpace: "normal", padding: "1px" }}
                      >
                        <td
                          style={{
                            textAlign: "center",
                            fontSize: "20px",
                            whiteSpace: "normal",
                            padding: "1px",
                          }}
                          className="page1-td"
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            fontSize: "20px",
                            whiteSpace: "normal",
                            padding: "1px",
                          }}
                          className="page1-td"
                        >
                          {item?.sub_account?.acode}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            fontSize: "20px",
                            whiteSpace: "normal",
                            padding: "1px",
                          }}
                          className="page1-td"
                        >
                          {item?.sub_account?.title}
                        </td>
                        <td
                          className="page1-td text-center"
                          style={{
                            fontSize: "20px",
                            whiteSpace: "normal",
                            padding: "1px",
                          }}
                        >
                          {item.debit}
                        </td>
                        <td
                          className="page1-td text-center"
                          style={{
                            fontSize: "20px",
                            whiteSpace: "normal",
                            padding: "1px",
                          }}
                        >
                          {item.credit}
                        </td>
                      </tr>
                    );
                  })}
                  <tr style={{ whiteSpace: "normal", padding: "1px" }}>
                    <td
                      className="page1-td"
                      style={{ whiteSpace: "normal", padding: "1px" }}
                    ></td>
                    <td
                      className="page1-td"
                      style={{ whiteSpace: "normal", padding: "1px" }}
                    ></td>
                    <td
                      className="page1-td text-center"
                      style={{
                        fontSize: "20px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      <b>Total</b>
                    </td>
                    <td
                      className="page1-td text-center"
                      style={{
                        fontSize: "20px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      <b>
                        {summary.total_debit == null
                          ? 0
                          : parseInt(summary.total_debit)}
                      </b>
                    </td>
                    <td
                      className="page1-td text-center"
                      style={{
                        fontSize: "20px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      <b>
                        {summary.total_credit == null
                          ? 0
                          : parseInt(summary.total_credit)}
                      </b>
                    </td>
                  </tr>
                </table>
                <div className="lower-content">
                  <div className="lower1">
                    <div className="lower2">
                      <p style={{ fontSize: "20px" }}>Prepared By</p>
                    </div>
                  </div>
                  <div className="lower1">
                    <div className="lower2">
                      <p style={{ fontSize: "20px" }}>Approved By</p>
                    </div>
                  </div>
                  <div className="lower1" id="lowerLast">
                    <div className="lower2">
                      {directorSign == 0 ? (
                        <p style={{ fontSize: "20px" }}>Chief Executive</p>
                      ) : (
                        <p style={{ fontSize: "20px" }}>Director</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
